(() => {
    const ctaHelpButton = document.querySelector('.help-button > button');
    const chatIcon = document.querySelector('.help-button > button .chat-icon');
    const chevronIcon = document.querySelector('.help-button > button .chevron-icon');
    const ctaMenuList = document.querySelector('.help-button > ul');
    const ctaMenusListLinks = document.querySelectorAll('.help-button ul li a');

    if (ctaHelpButton) {
        ctaHelpButton.addEventListener('click', () => {
            if (ctaMenuList) {
                ctaMenuList.classList.toggle('show');
                chatIcon.classList.toggle('d-none');
                chevronIcon.classList.toggle('d-none');
            }
        });
    }

    const navigateToLink = (event) => {
        let linkElement = event.currentTarget;
        let URL = linkElement.href;
        let title = linkElement.text.trim();

        try {
            Liferay.ThemeDisplay.trackEvents('cta', {
                category: 'Help Button',
                title,
                URL
            })
        } catch(error) {
            console.log(`Cannot send events: ${error || 'Something went wrong!'}`);
        }
    }

    if (ctaMenusListLinks) {
        ctaMenusListLinks.forEach(link => link.addEventListener('click', navigateToLink));
    }

    window.addEventListener('click', (event) => {
        let clickedTarget = event.target;

        if (!ctaHelpButton) return;

        if (clickedTarget === ctaHelpButton || clickedTarget === ctaMenuList) {
            return;
        }

        if (ctaHelpButton.contains(clickedTarget) || ctaMenuList.contains(clickedTarget)) {
            return;
        }


        ctaMenuList.classList.remove('show');
        chatIcon.classList.remove('d-none');
        chevronIcon.classList.add('d-none');
    });

})()

